import React from 'react';
import {graphql, useStaticQuery, Link} from 'gatsby';
import {css} from '@emotion/core';
import Img from "gatsby-image";
import Layout from '../../components/layout';
import Intro from '../../components/intro';
import ProjectQuote from '../../components/project-quote';
import ProjectMeta from '../../components/project-meta';
import Logo from '../../assets/logoSOUTH32.svg';
import LogoSQL from '../../assets/microsoft-sql-server.svg';
import LogoWebAPI from '../../assets/microsoft-dot-net.png';
import LogoAngular from '../../assets/angular.svg';
import LogoIonic from '../../assets/ionic.svg';
import ProjectWrapper from '../../components/project-wrapper';
import ProjectNav from '../../components/project-nav';
import NavArrow from '../../assets/navArrow.svg';

const South32 = () => {
  const {take5iphone, ipadTake5, south32adminLaptop} = useStaticQuery(graphql`
    query {
      south32adminLaptop: file(relativePath: { eq: "south32/south32adminLaptop.jpg" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      take5iphone: file(relativePath: { eq: "south32/take5iphone.jpg" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ipadTake5: file(relativePath: { eq: "south32/ipadTake5.jpg" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <Layout page="Our Work">
      <Intro>
        <h2>Enabling South32 to conduct safety audits across their assets</h2>
        <p>
          Keeping your workforce safe is crucial for any business. South32 needed an easy to use system
          to track, report and monitor their safety requirements in real time.
        </p>
      </Intro>

      <ProjectWrapper>
        <div className="project">
          <h3>The Project</h3>
          <p>
            The <strong>Safety Verification System</strong> is a simple, yet effective tool to allow South32 personnel
            to conduct safety audits across their assets and to submit personal safety records before starting any work.
            The project itself involved the design and development of a standalone database, secure web application and
            both iOS and Android mobile apps for in-field data capture with offline capabilities.</p>
        </div>

        <img src={Logo} alt="South32 logo" className="logo south32" />

        <div className="solution">
          <h3>The Solution</h3>
          <p>We worked with South32 to develop a simple safety tool that included:</p>
          <ul>
              <li>Fatal Risk Control Verification</li>
              <li>Safety Observations</li>
              <li>Take 5 Personal Safety Records</li>
              <li>Reporting and Data Export</li>
              <li>Offline Capability with Auto Sync</li>
              <li>System Administration</li>
          </ul>

          <p>We also manage the day to day operations of the platform including:</p>
          <ul>
            <li>Cloud Hosting</li>
            <li>System Backups and Monitoring</li>
            <li>Performance Tuning and Optimisation</li>
            <li>Feature Enhancements</li>
            <li>Support and Maintenance</li>
          </ul>
        </div>

        <div className="screens">
          <Img fluid={take5iphone.sharp.fluid} fadeIn alt="Dashboard page" />
          <Img fluid={south32adminLaptop.sharp.fluid} fadeIn alt="Jobs page on iPad" />
        </div>

        <ProjectMeta>
          <div className="requirements">
            <h3>The Development</h3>
            <ul>
              <li>Web Application Development</li>
              <li>Mobile App Development</li>
              <li>Systems Integration</li>
              <li>Automated Reporting</li>
              <li>UX Design</li>
              <li>Responsive Design</li>
            </ul>
          </div>
        </ProjectMeta>

        <Img fluid={ipadTake5.sharp.fluid} fadeIn alt="Jobs page on mobile" className="mainImage" />

        <ProjectMeta>
          <h3 className="techHeading">The Technology</h3>
          <div className="techLogos">
            <img src={LogoSQL} alt="Microsoft SQL logo" />
            <img src={LogoWebAPI} alt="Microsoft WebAPI logo" />
            <img src={LogoAngular} alt="Angular logo" />
            <img src={LogoIonic} alt="Ionic logo" />
          </div>
        </ProjectMeta>

        <ProjectQuote>
          Luke and the team provided quality advice and support along with the technical expertise. The outcome was a
          simple but effective solution that increased safety and efficiency at our operation.
        </ProjectQuote>
      </ProjectWrapper>

      <ProjectNav>
        <div className="prev">
          <img src={NavArrow} />
          <Link to="/work/sawu">Sawu</Link>
        </div>
        <div className="next">
          <Link to="/work/informia">Informia</Link>
          <img src={NavArrow} />
        </div>
      </ProjectNav>
    </Layout>
  );
};

export default South32;
